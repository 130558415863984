/** @format */

setupOmisePayment = (omisePublicKey, selector, errorMessage, isFree) => {
	setTimeout(() => {
		if (!isFree) {
			selectPayment('payment_method_credit_card', 'credit_card')
		}
		sumPrice()
		OmiseCard.configure({
			publicKey: omisePublicKey,
		})

		var button = document.querySelector('button.payment-omise')
		var form = document.querySelector(selector)

		button.addEventListener('click', (event) => {
			event.preventDefault()
			if (isFree) {
				form.submit()
			} else {
				openOmisePayment(form, errorMessage)
			}
		})

		button.disabled = false
	}, 1000)
}

selectPayment = (id, key) => {
	document
		.querySelectorAll('div.payment-card-select')
		.forEach((doc) => doc.classList.remove('card-selected'))

	document.getElementById(id).classList.add('card-selected')
	document.getElementById('subscription_omise_payment_method').value = key
}

onChangeQuantity = () => {
	sumPrice()
}

toMoney = (num) => {
	return num.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
}

toOmiseAmount = (totalAmount) => {
	if (isInt(totalAmount)) {
		return `${totalAmount}00`
	}

	return totalAmount.toString().replace('.', '')
}

isInt = (num) => {
	return num % 1 === 0
}

sumPrice = () => {
	let amount = document.getElementById('subscription_amount').value
	let withholdingTax = 0
	let totalAmount = 0

	try {
		let count = document.getElementById('subscription_quantity').value
		count = parseInt(count < 0 ? 1 : count) || 1
		let newAmount = +amount * count
		withholdingTax = 1000 <= newAmount ? (parseFloat(newAmount) / 100) * 3 : 0
		totalAmount = parseFloat(newAmount) - withholdingTax
		document.getElementById('subscription_omise_amount').value =
			toOmiseAmount(totalAmount)
		document.getElementById('price').innerText = toMoney(newAmount)
		document.getElementById('withholding_tax').innerText =
			toMoney(withholdingTax)
		document.getElementById('sum_price').innerText = toMoney(totalAmount)
	} catch (err) {
		console.log('Err => ', err)
		document.getElementById('subscription_quantity').value = 1
		document.getElementById('price').innerText = toMoney(amount)
		document.getElementById('withholding_tax').innerText =
			toMoney(withholdingTax)
		document.getElementById('sum_price').innerText = toMoney(totalAmount)
	}
}

openOmisePayment = (form, message) => {
	let amount = document.getElementById('subscription_omise_amount').value
	if (amount === '000') {
		form.submit()
	} else {
		let payment_method = document.getElementById(
			'subscription_omise_payment_method'
		).value
		OmiseCard.open({
			amount: amount,
			currency: 'THB',
			defaultPaymentMethod: payment_method,
			onCreateTokenSuccess: (nonce) => {
				if (nonce.startsWith('tokn_')) {
					form.subscription_omise_token.value = nonce
				} else {
					form.subscription_omise_source.value = nonce
				}

				form.submit()
			},
			onFormClosed: () => {
				showToast(message)
			},
		})
	}
}
